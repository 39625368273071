/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div
          style={{
            height: '100%'
          }}
        >
          <Header siteTitle={data.site.siteMetadata.title} />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 1260,
              padding: `0px 1.0875rem 1.45rem`,
              paddingTop: 0,
              height: 'calc(100% -  112px - 75px)',
              minHeight: 600
            }}
            className="superWrap"
          >
            <main>{children}</main>

          </div>
          <footer>
            <div
              style={{
                height: 75,
                width: '100%',
                backgroundColor: '#00B2D1',
              }}
            >
              <div
                style={{
                  margin: `0 auto`,
                  maxWidth: 1260,
                  padding: `0px 1.0875rem 1.45rem`,
                  padding: 20,
                }}
              >
                <div
                  style={{
                    float: 'left'
                  }}
                >
                  © 2019 Angelwetter.net
                </div>
                <div
                  style={{
                    float: 'right'
                  }}
                >
                  Ein Produkt der Hunting New Media GmbH Co. KG
                </div>
              </div>
            </div>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
