import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/20190327_Angelwetter_Logo_Breit_4C.png";

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#1A233B`,
      paddingTop: 20,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1260,
      }}
    >
      <img src={logo} />
      <div
        style={{
          width: '50%',
          float: 'right',
          textAlign: 'right',
          paddingTop: 20
        }}
      >
        <a href="https://itunes.apple.com/de/app/Angelwetter/id1456745135" target="_blank" style={{ marginRight: 50 }}>
        Für iOS
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.hnm.fishingweather" target="_blank">
        Für Android
        </a>
      </div>
    </div>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
